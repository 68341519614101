/* ****** BTN-OUTLINE ****** */

.btn-outline {
    margin-top: 15px;
    border: solid 2px #fff;
    font-size: 20px;
    color: #fff;
    background: 0 0;
    transition: all 0.3s ease-in-out;
}

    .btn-outline:hover,
    .btn-outline:focus,
    .btn-outline:active,
    .btn-outline.active {
        border: solid 2px #fff;
        color: #18bc9c;
        background: #fff;
    }

/* ****** BTN-SOCIAL ****** */
.btn-social {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 2px solid #fff;
    border-radius: 100%;
    text-align: center;
    font-size: 20px;
    line-height: 45px;
}

.btn:focus,
.btn:active,
.btn.active {
    outline: 0;
}

