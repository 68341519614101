/* ****** FOOTER ****** */
footer {
    color: #fff;
}

    footer h3 {
        margin-bottom: 30px;
    }

    footer a {
        color: white;
    }

        footer a:hover {
            color: grey !important;
        }

        footer a:focus {
            color: white;
        }

    footer ul {
        list-style: none;
        color: #fff;
    }

    footer .footer-above {
        padding-top: 25px;
        background-color: #2c3e50;
        color: #fff;
    }

    footer .footer-col {
        margin-bottom: 25px;
    }

    footer .footer-below {
        padding: 25px 0;
        background-color: #233140;
    }
