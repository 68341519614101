.info_container {
    margin: 0px auto 5px;
    padding: 20px 10px 20px 10px;
    width: 100%;
    /*background-color: #FFF;*/
    -moz-box-shadow: 0;
    -webkit-box-shadow: 0;
    box-shadow: 0;
}

@media(min-width: 1060px) {
    .info_container {
        margin: 0px auto 5px;
        padding: 20px 10px 20px 10px;
        width: 1050px;
        /*background-color: #FFF;*/
        -moz-box-shadow: 0 0 5px 5px #CCC;
        -webkit-box-shadow: 0 0 5px 5px #CCC;
        box-shadow: 0 0 5px 5px #CCC;
    }
}

/******* SECTION *******/

.info .info-item {
    right: 0;
    margin: 0 0 15px;
}

    .info .info-item .info-link {
        display: block;
        position: relative;
        margin: 0 auto;
        /*controla o tamanho de caption-content*/
        max-width: 200px;
    }

        .info .info-item .info-link .caption {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            background: rgba(24,188,156,.9);
            -webkit-transition: all ease .5s;
            -moz-transition: all ease .5s;
            transition: all ease .5s;
        }

            .info .info-item .info-link .caption:hover {
                opacity: 1;
            }

            .info .info-item .info-link .caption .caption-content {
                position: absolute;
                top: 50%;
                width: 100%;
                height: 20px;
                margin-top: -12px;
                text-align: center;
                font-size: 20px;
                color: #fff;
            }

                .info .info-item .info-link .caption .caption-content i {
                    margin-top: -12px;
                }

                .info .info-item .info-link .caption .caption-content h3,
                .info .info-item .info-link .caption .caption-content h4 {
                    margin: 0;
                }

.info * {
    z-index: 2;
}

@media(min-width: 767px) {
    .info .info-item {
        margin: 0 0 30px;
    }
}

/* ****** info-modal ****** */

.info-modal .container {
    padding-top: 30px;
    padding-bottom: 0px;
    margin: 0 0 0 0;
    width: 100%;
    background-color: #FFF;
    -moz-box-shadow: 0;
    -webkit-box-shadow: 0;
    box-shadow: 0;
}

@media (min-width: 1060px) {
    .info-modal .container {
        margin: 0px auto 0px;
        padding: 20px 20px 20px 20px;
        width: 900px;
        background-color: #FFF;
        -moz-box-shadow: 0 0 5px 5px #CCC;
        -webkit-box-shadow: 0 0 5px 5px #CCC;
        box-shadow: 0 0 5px 5px #CCC;
    }
}

.info-modal .modal-content {
    padding-top: 10px;
    min-height: 100%;
    border: 0;
    border-radius: 0;
    text-align: center;
    background-clip: border-box;
    -webkit-box-shadow: none;
    box-shadow: none;
}

    .info-modal .modal-content h2 {
        margin: 0;
        font-size: 3em;
    }

    .info-modal .modal-content img {
        margin-bottom: 30px;
    }

    .info-modal .modal-content .item-details {
        margin: 30px 0;
    }

.info-modal .close-modal {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 75px;
    height: 75px;
    background-color: transparent;
    cursor: pointer;
}

    .info-modal .close-modal:hover {
        opacity: .3;
    }

    .info-modal .close-modal .lr {
        z-index: 1051;
        width: 1px;
        height: 75px;
        margin-left: 35px;
        background-color: #2c3e50;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

        .info-modal .close-modal .lr .rl {
            z-index: 1052;
            width: 1px;
            height: 75px;
            background-color: #2c3e50;
            -webkit-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg);
        }

.info-modal .modal-backdrop {
    display: none;
    opacity: 0;
}
