/* ****** NAVBAR ****** */
.navbar-default {
    background-color: #2c3e50;
    /*border-color: #E7E7E7;*/
    margin-top: 60px;

}
    /* title */
    .navbar-default .navbar-brand {
        color: #fff;
    }

    .navbar-default .dummy {
        margin-top: 30px;
        color: white;
        float: right;
    }

    .navbar-default .navbar-text {
        font-size: 1em;
        font-style: italic;
        color: red;
    }

    .navbar-default .navbar-brand:hover,
    .navbar-default .navbar-brand:focus {
        color: #fff;
    }

    /* link */
    .navbar-default .navbar-nav > li > a {
        color: #fff;
    }

        .navbar-default .navbar-nav > li > a:hover,
        .navbar-default .navbar-nav > li > a:focus {
            color: white;
        }

        .navbar-default .navbar-nav > li > a:hover {
            color: grey;
        }

        .navbar-default .navbar-nav > li > a:focus {
            color: white;
        }

    .navbar-default .navbar-nav > .active > a,
    .navbar-default .navbar-nav > .active > a:hover,
    .navbar-default .navbar-nav > .active > a:focus {
        color: #555;
        background-color: #E7E7E7;
    }

    .navbar-default .navbar-nav > .open > a,
    .navbar-default .navbar-nav > .open > a:hover,
    .navbar-default .navbar-nav > .open > a:focus {
        color: #555;
        background-color: #D5D5D5;
    }

    /* caret */
    .navbar-default .navbar-nav > .dropdown > a .caret {
        border-top-color: #fff;
        border-bottom-color: #fff;
    }

    .navbar-default .navbar-nav > .dropdown > a:hover .caret,
    .navbar-default .navbar-nav > .dropdown > a:focus .caret {
        border-top-color: grey;
        border-bottom-color: grey;
    }

    .navbar-default .navbar-nav > .open > a .caret,
    .navbar-default .navbar-nav > .open > a:hover .caret,
    .navbar-default .navbar-nav > .open > a:focus .caret {
        border-top-color: grey;
        border-bottom-color: grey;
    }

/*mobile version*/

/*.navbar-default .navbar-toggle {
        border-color: #DDD;
    }

        .navbar-default .navbar-toggle:hover,
        .navbar-default .navbar-toggle:focus {
            background-color: #DDD;
        }

        .navbar-default .navbar-toggle .icon-bar {
            background-color: #CCC;
        }*/

@media (min-width: 768px) and (max-width: 1200px) {
    .navbar-collapse.collapse {
        display: none !important;
    }
    .navbar-collapse.collapse.in {
        display: block !important
    }
    .navbar-header .collapse, .navbar-toggle {
        display:block !important;
    }
    .navbar-header {
        float:none;
    }
}

@media (max-width: 767px) {
    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        color: #fff;
    }

        .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
        .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
            color: white;
        }
        .navbar-default .navbar-brand {
        font-size: 1em;
        }
}

.firstBar {
    /*width: 100%;*/
    position: fixed;
    height: 60px;
    width: 100vw;
    top: 0;
    /* margin-top: -120px; */
    padding-right: 30px;
    /* margin-left: -1vw; */
    /* left: 50%; */
    background-color: #233140;
    z-index: 6; position: fixed;
}

#first_container {
    margin: 10px 10px;
    width: 50%;
}

#socials_container {
  margin: 15px 110px 0 0;
  float: right;
}

#header_container {
    color: white;
    margin: 10px 20px;
    float: right;
}

@media (max-width: 767px) {
  .firstBar {
      /*width: 100%;*/
      height: 60px;
      width: 100vw;
      margin-top: 0vw;
      /* padding-right: 30px; */
      /* margin-left: -1vw; */
      /* left: 50%; */
      background-color: #233140;
      z-index: 6; position: fixed;
  }

  #first_container {
      margin: 10px 0px;
  }

  #socials_container {
    margin: 15px 5px 0 0;
    float: right;
  }

  #header_container {
      color: white;
      margin: 10px 0px;
      float: right;
  }
}
