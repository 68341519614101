/******* SECTION *******/
section {
    margin: 0 0 0 0;
    padding: 10px 0 10px 0;
}

    section h2 {
        margin: 0;
        font-size: 3em;
    }

    section img {
        display: block;
        margin: 0 auto 0px;
        padding: 0 0 0 0;
        border: 0 0 0 0;
    }

    section.success {
        background: #ffffff;
        color: white;
    }

        section.success a,
        section.success a:hover,
        section.success a:focus,
        section.success a:active,
        section.success a.active {
            color: #2C3E50;
            outline: none;
        }

    section .container {
        margin: 10px auto 10px;
        padding: 20px 20px 20px 20px;
        width: 1050px;
        background-color: #FFF;
        -moz-box-shadow: 0 0 5px 5px #CCC;
        -webkit-box-shadow: 0 0 5px 5px #CCC;
        box-shadow: 0 0 5px 5px #CCC;
    }

@media(max-width: 1050px) {
    section {
        /*padding: 10px 0;*/
        margin: 0px 0 0 0;
        padding: 0px 0 0px 0;
    }

        section .first {
            padding-top: 10px;
        }

        section img {
            display: block;
            margin: 0 auto 0px;
            padding: 0 0 0 0;
            border: 0 0 0 0;
        }

        section .container {
            padding-top: 10px;
            padding-bottom: 30px;
            margin: 0 0 0 0;
            width: 100%;
            background-color: #FFF;
            -moz-box-shadow: 0;
            -webkit-box-shadow: 0;
            box-shadow: 0;
        }
}
