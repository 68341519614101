/* ****** HEADER ****** */
header {
    text-align: center;
    /*background: #ffffff;*/
    color: black;
}

    header .container {
        padding-top: 120px;
        padding-bottom: 0px;
        margin: 0 0 0 0;
        width: 100%;
        background-color: #FFF;
        /*-moz-box-shadow: 0;
        -webkit-box-shadow: 0;
        box-shadow: 0;*/
    }

    header img {
        display: block;
        margin: 0 auto 0px;
        padding: 0 0 0 0;
        border: 0 0 0 0;
    }

    header .intro-text .name {
        display: block;
        text-transform: uppercase;
        font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 2em;
        font-weight: 700;
    }

    header .intro-text .skills {
        font-size: 1.25em;
        font-weight: 300;
    }

@media (min-width: 768px) {
    header .container {
        margin: 120px auto 0px;
        padding: 10px 0 0 0;
        /*width: 1050px;*/
        background-color: #FFF;
        /*-moz-box-shadow: 0 0 5px 5px #CCC;
        -webkit-box-shadow: 0 0 5px 5px #CCC;
        box-shadow: 0 0 5px 5px #CCC;*/
    }

    header img {
        display: block;
        margin: 0 auto 0px;
        padding: 0 0 0 0;
        border: 0 0 0 0;
    }

    header .intro-text .name {
        font-size: 4.75em;
    }

    header .intro-text .skills {
        font-size: 1.75em;
    }
}
